<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-9">
        <div class="row">
          <button
            class="btn border border-light col-sm-2 mx-3"
            :class="isPisoSala ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'PisoSala'"
          >
            <b>Piso - Sala</b>
          </button>
          <button
            class="btn border border-light col-sm-2 mr-3"
            :class="isSalaCama ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'SalaCama'"
          >
            <b>Sala - Cama</b>
          </button>
          <button
            class="btn border border-light col-sm-2 mr-3"
            :class="isPisoConsultorio ? 'btn-success text-dark': 'btn-white'"
            @click="componentShow = 'PisoConsultorio'"
          >
            <b>Piso - Consultorio</b>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <PisoSala v-if="isPisoSala"/>
      <SalaCama v-if="isSalaCama"/>
      <PisoConsultorio v-if="isPisoConsultorio"/>
    </div>
  </div>
</template>

<script>
import PisoSala from './ThePisoSala.vue';
import SalaCama from './TheSalaCama.vue';
import PisoConsultorio from './ThePisoConsultorio.vue';

export default {
  name: 'Asignar',
  components: {
    PisoSala,
    SalaCama,
    PisoConsultorio,
  },
  data() {
    return {
      componentShow: 'PisoSala',
    };
  },
  computed: {
    isPisoSala() {
      return (this.componentShow === 'PisoSala');
    },
    isSalaCama() {
      return (this.componentShow === 'SalaCama');
    },
    isPisoConsultorio() {
      return (this.componentShow === 'PisoConsultorio');
    },
  },
};
</script>

<style scoped>
</style>
